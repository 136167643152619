import { ApiClient, Tokens } from "@/generated/client";
import { View } from "@/router/typedefs";
import { useStorage } from "@vueuse/core";
import { JWTPayload } from "jose";
import { defineStore } from "pinia";
import { computed } from "vue";
import { useRouter } from "vue-router";

export type DecodedToken = JWTPayload & {
  email: string;
  picture: string;
  tokenExpiry: number;
};

export const useUserStore = defineStore("user", () => {
  const router = useRouter();

  const googleClientId = useStorage("clientIDV2", "");
  const accessToken = useStorage("acToken", "");
  const refreshToken = useStorage("refreshTokn", "");

  const isSignedIn = computed(() => accessToken.value !== "");

  const googleLogin = async (
    apiClient: ApiClient,
    credentials: string,
    clientId: string
  ) => {
    googleClientId.value = clientId;

    const response: Tokens =
      await apiClient.default.googleLoginUserLoginGooglePost({
        credentials: credentials,
      });

    accessToken.value = response.access;
    refreshToken.value = response.refresh;
  };

  const signOut = () => {
    googleClientId.value = "";
    accessToken.value = "";
    refreshToken.value = "";
  };

  const refreshUserTokens = async (apiClient: ApiClient) => {
    if (refreshToken.value === "") {
      signOut();
      await router.push({name: View.LOGIN})
      throw new Error("Cannot refresh with empty refresh token.");
    }

    const response: Tokens =
      await apiClient.default.refreshTokensUserRefreshPost({
        refresh: refreshToken.value,
      });

    accessToken.value = response.access;
    refreshToken.value = response.refresh;
  };

  return {
    // State.
    googleClientId,
    accessToken,
    refreshToken,
    // Computed.
    isSignedIn,
    // Methods.
    googleLogin,
    refreshUserTokens,
    signOut,
  };
});

export type UserStore = ReturnType<typeof useUserStore>;
