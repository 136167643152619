<template>
  <q-layout view="hHh lpR fFf">
    <q-page-container>
      <q-page>
        <router-view :api-client="props.apiClient" />
      </q-page>
    </q-page-container>
  </q-layout>
</template>
<script lang="ts" setup>
import { ApiClient } from "@/generated/client";
import { defineProps } from "vue";

const props = defineProps<{
  apiClient: ApiClient;
}>();

</script>
<style lang="scss" scoped>
.auth-layout {
  display: flex;
  height: 100%;
}
</style>
