<template>
  <q-layout view="hHh lpR fFf">
    <q-header elevated class="bg-primary text-white">
      <q-toolbar>
        <q-btn dense flat round icon="menu" @click="toggleLeftDrawer" />
        <q-toolbar-title v-if="$q.screen.gt.sm">
          ŁAD CMS v0.2.0</q-toolbar-title
        >
        <q-toolbar-title v-else>ŁAD</q-toolbar-title>
        <template v-if="userStore.isSignedIn">
          <q-btn @click="signOut" flat> Wyloguj się </q-btn>
        </template>
        <template v-else>
          <router-link :to="{ name: View.LOGIN }"
            >Zaloguj się
          </router-link></template
        >
      </q-toolbar>
    </q-header>

    <q-drawer
      padding
      class="drawer rounded-borders text-primary"
      show-if-above
      v-model="leftDrawerOpen"
      side="left"
      bordered
      :mini="miniState"
      @mouseover="miniState = false"
      @mouseout="miniState = true"
      mini-to-overlay
    >
      <q-list>
        <template v-for="(menuItem, index) in menuItems" :key="index">
          <q-item
            clickable
            :active="route.name === menuItem.to"
            :to="{ name: menuItem.to }"
            class="drawer-item"
            active-class="drawer-item--active"
          >
            <q-item-section avatar>
              <q-icon :name="menuItem.icon" />
            </q-item-section>
            <q-item-section>
              {{ menuItem.label }}
            </q-item-section>
          </q-item>
          <q-separator :key="'sep' + index" v-if="menuItem.separator" />
        </template>
      </q-list>
    </q-drawer>

    <q-page-container>
      <q-page>
        <router-view :api-client="props.apiClient"/>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script lang="ts" setup>
import { View } from "@/router/typedefs";
import { useUserStore } from "@/store/user";
import { useQuasar } from "quasar";
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ApiClient } from "@/generated/client";
import { defineProps } from "vue";

const props = defineProps<{
  apiClient: ApiClient;
}>();

interface MenuItem {
  label: string;
  icon: string;
  to: View;
  separator?: boolean;
}

const route = useRoute();
const userStore = useUserStore();
const router = useRouter();
const $q = useQuasar();

const leftDrawerOpen = ref(false);

const toggleLeftDrawer = () => {
  leftDrawerOpen.value = !leftDrawerOpen.value;
};

const menuItems: MenuItem[] = [
  {
    label: "Dodaj nowe zamówienie",
    icon: "add",
    to: View.ORDER_ADD_NEW,
  },
  {
    label: "Lista zamówień",
    icon: "list",
    to: View.ORDER_LIST,
  },
];

const signOut = () => {
  userStore.signOut();
  router.push({ name: View.LOGIN });
};

const miniState = ref(true);

if (!userStore.isSignedIn) {
  router.push({ name: View.LOGIN });
}
</script>

<style lang="scss" scoped>
.drawer-item {
  &--active {
    color: white;
    background: #f2c037;
  }
}

.picture {
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  margin-left: 0.5rem;
  border: 1px solid white;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}
</style>
