import { ApiClient } from "@/generated/client";
import { ApiClientV2 } from "./ApiClientV2";
import { FetchHttpRequestV2 } from "./FetchHttpRequestV2";

interface TokenProvider {
  accessToken: string;
  refreshUserTokens: (a: ApiClient) => Promise<void>;
}

type Headers = Record<string, never> | { Authorization: string };

const baseUrl = process.env.VUE_APP_API_BASE_URL ?? ""

export const apiClientFactory = (tokenProvider: TokenProvider) => {
  const config = {
    BASE: baseUrl,
    HEADERS: async (): Promise<Headers> =>
      tokenProvider.accessToken !== ""
        ? {
            Authorization: `Bearer ${tokenProvider.accessToken}`,
          }
        : {},
    VERSION: "0.0.1",
    WITH_CREDENTIALS: false,
    CREDENTIALS: "include" as const,
  };
  const requestor = new FetchHttpRequestV2(config);
  const clientInstance = new ApiClientV2(requestor);

  requestor.onTokenExpiredError(() =>
    tokenProvider.refreshUserTokens(clientInstance)
  );

  return clientInstance;
};

console.log({baseUrl})
