import { ApiClient, BaseHttpRequest, DefaultService } from "@/generated/client";


export class ApiClientV2 extends ApiClient {

    public readonly default: DefaultService;

    public readonly request: BaseHttpRequest;

    constructor(request: BaseHttpRequest) {
        super();
        this.request = request;
        this.default = new DefaultService(this.request);
    }
}
