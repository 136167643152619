import { View } from "./typedefs";
import AuthLayout from "@/layouts/AuthLayout.vue";
import MainLayout from "@/layouts/MainLayout.vue";
import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "mainLayout",
    component: MainLayout,
    redirect: { name: View.ORDER_LIST },
    children: [
      {
        path: "order",
        name: View.ORDER_LIST,
        component: () => import("@/views/OrderList.vue"),
        children: [],
      },

      {
        path: "order/new",
        name: View.ORDER_ADD_NEW,
        component: () => import("@/views/OrderNew.vue"),
      },

      {
        path: "order/edit/:orderId",
        name: View.ORDER_EDIT,
        component: () => import("@/views/OrderEdit.vue"),
        props: (route) => ({
          ...route.params,
          orderId: String(route.params.orderId),
        }),
      },

      {
        path: "settings",
        name: View.SETTINGS,
        component: () => import("@/views/AppSettings.vue"),
      },
    ],
  },
  {
    path: "/",
    name: "authLayout",
    component: AuthLayout,
    children: [
      {
        path: "login",
        name: View.LOGIN,
        component: () => import("@/views/LoginOAuth.vue"),
      },
    ],
  },
  {
    path: "/tos",
    name: "termsOfUse",
    component: AuthLayout,
    beforeEnter: () => {
      window.location.href =
        "https://piotrszyma.notion.site/AD-CMS-v1-0-ToS-PP-1c32aa65e552418585c1448f536fea25";
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
