<template>
  <router-view :api-client="apiClient" v-if="isLoaded"/>
    <div class="loading" v-else>
      <q-spinner
          size="3rem"
          :thickness="2"
        />
    </div>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import { apiClientFactory } from "./api/clientFactory";
import { useUserStore } from "./store/user";

const userStore = useUserStore();
const apiClient = apiClientFactory(userStore);

const isLoaded = ref(false);

async function refreshMaybeStaleTokens() {
  try {
    const newTokens = await apiClient.default.refreshTokensUserRefreshPost({
      refresh: userStore.refreshToken,
    });
    userStore.accessToken = newTokens.access;
    userStore.refreshToken = newTokens.refresh;
  } catch (error: unknown) {
    userStore.signOut();

    throw error;
  }

  isLoaded.value = true;
}

if (userStore.isSignedIn) {
  refreshMaybeStaleTokens()
} else {
  isLoaded.value = true;
}
</script>
<style lang="scss" scoped>
.loading {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
</style>
