import { ApiError, CancelablePromise, OpenAPIConfig } from "@/generated/client";
import { ApiRequestOptions } from "@/generated/client/core/ApiRequestOptions";
import { FetchHttpRequest } from "@/generated/client/core/FetchHttpRequest";

export class FetchHttpRequestV2 extends FetchHttpRequest {
  private onTokenExpiredFn?: () => Promise<void>;

  constructor(config: OpenAPIConfig) {
    super(config);
  }

  public onTokenExpiredError(onTokenExpiredFn: () => Promise<void>) {
    this.onTokenExpiredFn = onTokenExpiredFn;
  }

  public override request<T>(options: ApiRequestOptions): CancelablePromise<T> {
    return new CancelablePromise(async (resolve, reject, onCancel) => {
      const responsePromise = super.request<T>(options);
      onCancel(() => responsePromise.cancel());
      responsePromise
        .then((v) => resolve(v))
        .catch(async (e) => {
          if (e instanceof ApiError && e.status === 401) {
            await this.onTokenExpiredFn?.();
            try {
              const valueAfterRetry = await super.request<T>(options);
              resolve(valueAfterRetry);
            } catch (error: unknown) {
              reject(error);
            }
          } else {
            reject(e);
          }
        });
    });
  }
}
