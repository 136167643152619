/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Address } from '../models/Address';
import type { Company } from '../models/Company';
import type { Customer } from '../models/Customer';
import type { GoogleLoginData } from '../models/GoogleLoginData';
import type { NewOrder } from '../models/NewOrder';
import type { Order } from '../models/Order';
import type { OrdersList } from '../models/OrdersList';
import type { RefreshTokenData } from '../models/RefreshTokenData';
import type { Tokens } from '../models/Tokens';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DefaultService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Healthcheck
     * @returns any Successful Response
     * @throws ApiError
     */
    public healthcheckHealthzGet(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_healthz',
        });
    }

    /**
     * List Orders
     * @param description
     * @param limit
     * @param offset
     * @param query
     * @returns OrdersList Successful Response
     * @throws ApiError
     */
    public listOrdersOrderGet(
        description?: string,
        limit?: number,
        offset?: number,
        query?: string,
    ): CancelablePromise<OrdersList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/order',
            query: {
                'description': description,
                'limit': limit,
                'offset': offset,
                'query': query,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Order
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public createOrderOrderPost(
        requestBody: NewOrder,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/order',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Order
     * @param orderId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getOrderOrderOrderIdGet(
        orderId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/order/{order_id}',
            path: {
                'order_id': orderId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Order
     * @param orderId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateOrderOrderOrderIdPut(
        orderId: string,
        requestBody: Order,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/order/{order_id}',
            path: {
                'order_id': orderId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Refresh Tokens
     * @param requestBody
     * @returns Tokens Successful Response
     * @throws ApiError
     */
    public refreshTokensUserRefreshPost(
        requestBody: RefreshTokenData,
    ): CancelablePromise<Tokens> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/refresh',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Google Login
     * @param requestBody
     * @returns Tokens Successful Response
     * @throws ApiError
     */
    public googleLoginUserLoginGooglePost(
        requestBody: GoogleLoginData,
    ): CancelablePromise<Tokens> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/login/google',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List Addresses
     * @param value
     * @param limit
     * @param offset
     * @returns Address Successful Response
     * @throws ApiError
     */
    public listAddressesAddressGet(
        value: string,
        limit?: number,
        offset?: number,
    ): CancelablePromise<Array<Address>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/address',
            query: {
                'value': value,
                'limit': limit,
                'offset': offset,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List Customers
     * @param address
     * @param limit
     * @param offset
     * @returns Customer Successful Response
     * @throws ApiError
     */
    public listCustomersCustomerGet(
        address: string,
        limit?: number,
        offset?: number,
    ): CancelablePromise<Array<Customer>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/customer',
            query: {
                'address': address,
                'limit': limit,
                'offset': offset,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Find Company
     * @param nip
     * @returns Company Successful Response
     * @throws ApiError
     */
    public findCompanyCompanyNipGet(
        nip: string,
    ): CancelablePromise<Company> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/company/{nip}',
            path: {
                'nip': nip,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
