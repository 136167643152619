import App from "./App.vue";
import quasarUserOptions from "./quasar-user-options";
import router from "./router";
import { createPinia } from "pinia";
import Quasar from 'quasar/src/vue-plugin.js';;
import { createApp } from "vue";
import * as Sentry from "@sentry/vue";

const app = createApp(App)
  .use(Quasar, quasarUserOptions)
  .use(router)
  .use(createPinia())

console.log({commitSha: process.env.VUE_APP_COMMIT_SHA ?? ""})

Sentry.init({
  app,
  dsn: "https://37d8b81f3322c2348e45948136a0f008@o304963.ingest.sentry.io/4505682369642496",
  integrations: [
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.mount("#app");
